<template>
  <div style="width: 100%; padding: 0 20px">
    <div class="configure">
      <!-- <div class="title">
        <span >通知标题</span>
      </div> -->

      <div style="margin-bottom: 72px">
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="100px"
          class="demo-ruleForm"
        >
          <el-form-item label="通知标题:" prop="notice_title">
            <el-select v-model="ruleForm.notice_type" placeholder="请选择">
              <el-option
                :label="item.title"
                v-for="(item, index) in NotificationType"
                :key="index"
                :value="item.id"
              >
              </el-option>
            </el-select>
            <el-input
              class="margin-left-5"
              style="width: 80%"
              v-model="ruleForm.notice_title"
            ></el-input>
          </el-form-item>
          <el-form-item label="上传附件:">
            <el-upload
              class="upload-demo"
              ref="upload"
              :action="uploadActionUrl"
              :on-preview="handlePreview"
              :on-remove="handleRemove"
              :on-success="uploadSuccess"
              :file-list="fileList"
              :auto-upload="true"
            >
              <el-button slot="trigger" size="small" type="primary"
                >点击上传附件</el-button
              >
            </el-upload>
          </el-form-item>
          <el-form-item label="详情" style="width: 100%">
            <editor
              v-model="ruleForm.notice_content"
              :initial-val="ruleForm.notice_content"
              style="width: 80%"
              key-name="notice_content"
              @change="onAdvantageEditChange"
            ></editor>
          </el-form-item>
          <el-form-item label="查看权限">
            <div class="flex flex-wrap-wrap">
              <div class="margin-left-5">
                <el-checkbox-group
                  @change="permissions_change"
                  v-model="ruleForm.view_permissions"
                >
                  <el-checkbox :label="0" name="type">所有人</el-checkbox>
                  <el-checkbox
                    v-for="(item, index) in ViewPermissions"
                    :key="index"
                    :label="item.id"
                    name="type"
                    >{{ item.name }}</el-checkbox
                  >
                </el-checkbox-group>
              </div>
            </div>
          </el-form-item>
          <el-form-item label="通知校区">
            <div class="flex flex-wrap-wrap">
              <div class="margin-left-5">
                <el-checkbox-group
                  @change="school_change"
                  v-model="ruleForm.school_id"
                >
                  <el-checkbox :label="0" name="type">全部</el-checkbox>
                  <el-checkbox
                    :label="item.id"
                    v-for="(item, index) in school_list"
                    :key="index"
                    name="type"
                    >{{ item.name }}</el-checkbox
                  >
                </el-checkbox-group>
              </div>
            </div>
          </el-form-item>
          <el-form-item label="权重"  >
            
            <el-input 
              v-model="ruleForm.weight_int "
              style="width:50%"
              type="number" 
            ></el-input>
          </el-form-item>

        </el-form>
      </div>
    </div>
    <div class="fabu_position flex justify-content-center">
      <el-button @click="JumpUrl('/InternalManagement/staffNotice')"
        >取消</el-button
      >
      <el-button type="primary" @click="submitForm('ruleForm')">发送</el-button>
    </div>
  </div>
</template>
<script>
import Editor from "@/components/Editor/index";
export default {
  components: { Editor },
  data() {
    return {
      enclosure: [],
      fileList: [],
      noticeId: "", //通知id
      school_list: [], //校区列表
      ViewPermissions: [], //查看权限列表
      NotificationType: [],
      value: "",
      datalist_enterprise: [],
      form: {
        wap_desc: "",
      },
      editor2Val: "",
      ruleForm: {
        enclosure: "",
        notice_title: "", //通知标题
        notice_type: "", //通知类型
        notice_content: "", //通知内容
        view_permissions: [], //查看权限
        school_id: [], //校区
      },
      rules: {
        notice_title: [
          { required: true, message: "请输入通知标题", trigger: "blur" },
          // { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
        ],
      },
    };
  },
  created() {
    if (this.$route.query.id) {
      this.noticeId = this.$route.query.id;
    }
    this.getdata();
  },
  methods: {
    //头像上传 accountQuery
    // beforeAvatarUpload(file) {
    //   debugger;
    //   // const isJPG =
    //   //   file.type.toLowerCase() === "image/jpeg" ||
    //   //   file.type.toLowerCase() === "image/png" ||
    //   //   file.type.toLowerCase() === "image/jpg";
    //   // const isLt2M = file.size / 1024 / 1024 < 2;
    //   // if (!isJPG) {
    //   //   this.$message.error("上传头像图片只能是 JPG,JPEG,PNG 格式!");
    //   // }
    //   // if (!isLt2M) {
    //   //   this.$message.error("上传头像图片大小不能超过 2MB!");
    //   // }
    //   // return isJPG && isLt2M;
    // },
    handleRemove(file, fileList) {
      this.enclosure = this.enclosure.filter((rs) => {
        // console.log(rs.FileName + "----" + file.response.data.FileName);
        return rs.Name != file.name;
      }); 
    },
    handlePreview(file) {
      console.log(file);
    },
    uploadSuccess(res) {
      this.enclosure.push(res.data);
      // this.resetForm.enclosure = JSON.stringify(res.data);
      // this.avatar = res.data.Uri;
      // this.stuForm.avatar = res.data.src;
    },

    permissions_change(e) {
      let is_all = e[e.length - 1] == 0;
      if (is_all) {
        this.ruleForm.view_permissions = [0];
      } else {
        let select = this.ruleForm.view_permissions.filter((rs) => {
          return rs != 0;
        });
        this.ruleForm.view_permissions = select;
      }
    },
    school_change(e, a, b) {
      let is_all = e[e.length - 1] == 0;
      if (is_all) {
        this.ruleForm.school_id = [0];
      } else {
        let select = this.ruleForm.school_id.filter((rs) => {
          return rs != 0;
        });
        this.ruleForm.school_id = select;
      }
    },
    JumpUrl(url, id) {
      this.$router.push(url);
    },
    getdata() {
      //获取通知标题内容
      this.$Dictionary
        .GetDictionaryAll(this.$Dictionary.moduleConfig.NotificationType)
        .then((res) => {
          this.NotificationType = res.data.rows.reverse();

          this.NotificationType = this.NotificationType.filter((x) => {
            return x.parent_id != 0;
          });
          if (this.NotificationType.length) {
            this.ruleForm.notice_type = this.NotificationType[0].id;
          }
        });
      //roleQuery获取查看权限
      var model = {
        school_id: 0,
        type: [1],
        sys_tag: "",
        KeyWord: "",
        PageIndex: 1,
        PageSize: 1000000,
      };
      this.API.roleQuery(model).then((res) => {
        this.ViewPermissions = res.data.rows;
      });
      //查询校区
      var modelschool = { type: 1 };

      this.API.OrganizationList(modelschool).then((res) => {
        this.school_list = res.data.rows;
      });
      //获取通知
      if (this.noticeId) {
        this.API.Get_Notice({ id: this.noticeId }).then((res) => {
          this.ruleForm = res;
          if (this.ruleForm.school_id) {
            // this.ruleForm.school_id = JSON.parse(this.ruleForm.school_id);

            this.ruleForm.school_id = this.ruleForm.school_id.split(",");
            this.ruleForm.school_id = this.ruleForm.school_id.map((rs) => {
              return parseInt(rs);
            });
          }
          if (this.ruleForm.enclosure) {
            this.enclosure = JSON.parse(this.ruleForm.enclosure);
            this.fileList = this.enclosure.map((t) => {
              return {
                name: t.Name,
                url: t.Uri,
              };
            });
            console.log("fileList", this.fileList);
          }
          console.log(this.fileList);
          if (this.ruleForm.view_permissions) {
            this.ruleForm.view_permissions = this.ruleForm.view_permissions.split(
              ","
            );
            this.ruleForm.view_permissions = this.ruleForm.view_permissions.map(
              (rs) => {
                return parseInt(rs);
              }
            );
          }
        });
      }
    },
    onAdvantageEditChange(type, html) {
      this.form.wap_desc = html;
      this.ruleForm.notice_content = this.form.wap_desc;
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.addNotice();
        } else {
          return false;
        }
      });
    },
    addNotice() { 
      this.ruleForm.view_permissions = this.ruleForm.view_permissions.join(",");
      this.ruleForm.enclosure = JSON.stringify(this.enclosure);
      this.ruleForm.school_id = this.ruleForm.school_id.join(",");
      if (this.noticeId) {
        this.ruleForm.id = this.noticeId;
      }
      this.API.NoticeCreateorupdate(this.ruleForm).then((res) => {
        
        if (res.success) {
          this.$message.success(res.message);
          this.JumpUrl("/InternalManagement/staffNotice");
        } else {
          this.$message.success(res.message);
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },
};
</script>

<style lang="scss">
.margin-left-5 {
  margin-left: 5px;
}
.fabu_position {
  position: fixed;
  width: 76%;
  bottom: 20px;
  left: 338px;
}
.configure {
  width: 100%;
  background-color: #fff;
  margin-top: 10px;
  padding: 10px;
}
.title {
  font-size: 18px;
  font-weight: bold;
  display: flex;
  .title-ys {
    border-left: 5px solid rgb(72, 172, 211);
    padding: 0 9px;
  }
}
.status-point {
  background-color: #e6a23c;
  margin-right: 5px;
  display: inline-block;
  width: 6px;
  height: 6px;
  border-radius: 50%;
}
</style>